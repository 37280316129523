import { useState, useEffect } from "react";
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";
import moment from "moment";

const DISPLAY = {
  COLUMN: "column",
  ROW: "row",
};

function getFileNameFromResponse(str) {
  if (!str || typeof str !== "string" || !str.trim()) {
    return "";
  }
  let arr = str.split("/");
  if (arr.length > 0 && arr[arr.length - 1] && arr[arr.length - 1].toLocaleLowerCase().includes(".pdf")) {
    return arr[arr.length - 1];
  }
  return "";
}

function ImageViewer(props) {
  const [imageViewerKey] = useState(moment().valueOf() + "-" + props.imageViewerKey);
  const [display] = useState(props.display ? props.display : DISPLAY.COLUMN);

  useEffect(() => {
    // register the element that contain images to the viewer
    new Viewer(document.getElementById("image-viewer-" + imageViewerKey), {
      title: () => {
        return props.heading ? props.heading : "";
      },
    });
  });

  return (
    <div
      id={"image-viewer-" + imageViewerKey}
      className={
        display === DISPLAY.COLUMN
          ? "grid w-full xl:grid-cols-2 max-[1280px]:grid-cols-1"
          : "grid w-full auto-cols-max min-[376px]:grid-flow-col max-[375px]:grid-cols-3"
      }
    >
      {props.images && props.images.length
        ? props.images.map((image, index) => {
            return image.object_name && (image.object_name.includes(".pdf") || image.object_name.includes(".PDF")) ? (
              <a href={image.link} target="_blank" rel="noreferrer" className="block hover:cursor-pointer">
                {getFileNameFromResponse(image.object_name)}
              </a>
            ) : (
              <div
                key={"image-" + index}
                className={
                  index === 3 && props.images.length > 4
                    ? "rounded m-1 flex items-center justify-center bg-[#000000] relative max-w-max"
                    : "rounded m-1 max-w-max"
                }
                hidden={index >= 4}
              >
                <img
                  src={`${image.link}`}
                  alt=""
                  className={
                    index === 3 && props.images.length > 4
                      ? display === DISPLAY.COLUMN
                        ? "rounded hover:cursor-pointer opacity-50"
                        : "rounded hover:cursor-pointer opacity-50 max-w-[100px] max-[500px]:max-w-[70px]"
                      : display === DISPLAY.COLUMN
                      ? "rounded hover:cursor-pointer"
                      : "rounded hover:cursor-pointer max-w-[100px] max-[500px]:max-w-[70px]"
                  }
                />
                {index === 3 && props.images.length > 4 ? (
                  <span className="absolute font-bold text-white max-[500px]:!text-[12px] text-[22px]">{props.images.length - 4}+</span>
                ) : null}
              </div>
            );
          })
        : null}
    </div>
  );
}

export default ImageViewer;
