import { useState } from "react";
import ImageViewer from "./commons/ImageViewer";

function initDrugList(data) {
  let drugs = [];
  if (data && data.length) {
    for (let e of data) {
      if (e.drug_items && e.drug_items.length) {
        drugs.push(...e.drug_items);
      }
    }
  }
  return drugs;
}

function initImageList(data) {
  let images = [];
  if (data && data.length) {
    for (let e of data) {
      if (e.images && e.images.length) {
        images.push(...e.images);
      }
    }
  }
  return images;
}

function initNotes(data) {
  let notes = "";
  if (data && data.length) {
    for (let e of data) {
      if (e.notes && e.notes.trim()) {
        notes += notes ? "\n" : "" + e.notes;
      }
    }
  }
  console.log(notes);
  return notes;
}

function ExaminationDrugs(props) {
  const [drugs] = useState(initDrugList(props.data));
  const [images] = useState(initImageList(props.data));
  const [notes] = useState(initNotes(props.data));

  return (
    <div id="examination_drugs_id" className="max-w-full">
      {notes ? <p className="mb-2 text-red-500">Ghi chú: {notes}</p> : null}
      {drugs && drugs.length ? (
        <table className="w-full border border-collapse border-[#EDF0F8] max-[500px]:!text-[12px]">
          <thead>
            <tr>
              <th className="bg-[#F3F7FF] border border-[#EDF0F8] uppercase max-[500px]:!text-[12px] text-[14px] text-[#1F2D3D] font-medium leading-[17px] max-[500px]:!px-[10px] max-[500px]:!py-[7px] px-[20px] py-[14px] text-center w-4">
                STT
              </th>
              <th className="bg-[#F3F7FF] border border-[#EDF0F8] uppercase max-[500px]:!text-[12px] text-[14px] text-[#1F2D3D] font-medium leading-[17px] max-[500px]:!px-[10px] max-[500px]:!py-[7px] px-[20px] py-[14px] text-left w-[20%]">
                Tên thuốc
              </th>
              <th className="bg-[#F3F7FF] border border-[#EDF0F8] uppercase max-[500px]:!text-[12px] text-[14px] text-[#1F2D3D] font-medium leading-[17px] max-[500px]:!px-[10px] max-[500px]:!py-[7px] px-[20px] py-[14px] text-left w-[25%]">
                Số lượng
              </th>
              <th className="bg-[#F3F7FF] border border-[#EDF0F8] uppercase max-[500px]:!text-[12px] text-[14px] text-[#1F2D3D] font-medium leading-[17px] max-[500px]:!px-[10px] max-[500px]:!py-[7px] px-[20px] py-[14px] text-left">
                Cách dùng
              </th>
            </tr>
          </thead>
          <tbody>
            {drugs.map((drug, idx) => {
              return (
                <tr key={idx}>
                  <td className="border border-[#EDF0F8] max-[500px]:!px-[10px] max-[500px]:!py-[5px] px-[20px] py-[10px] text-center">{idx + 1}</td>
                  <td className="border border-[#EDF0F8] max-[500px]:!px-[10px] max-[500px]:!py-[5px] px-[20px] py-[10px]">{drug.supplies_name}</td>
                  <td className="border border-[#EDF0F8] max-[500px]:!px-[10px] max-[500px]:!py-[5px] px-[20px] py-[10px]">
                    {drug.quantity} {drug.unit_name}
                  </td>
                  <td className="border border-[#EDF0F8] max-[500px]:!px-[10px] max-[500px]:!py-[5px] px-[20px] py-[10px]">{drug.uses}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : null}
      {images && images.length ? (
        <div className="w-full mt-2">
          <ImageViewer heading="Đơn thuốc" images={images} imageViewerKey="examination_drugs" display="row" />
        </div>
      ) : null}
    </div>
  );
}

export default ExaminationDrugs;
