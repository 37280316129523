import { useState, useEffect } from "react";
import Header from "./Header";
import ExaminationSummary from "./ExaminationSummary";
import ExaminationImageAnalysis from "./ExaminationImageAnalysis";
import ExaminationTest from "./ExaminationTest";
import ExaminationDrugs from "./ExaminationDrugs";
import { CALL_API_FUNCTIONS } from "../api/apis";
import moment from "moment";

function App() {
  const [result, setResult] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [tabIndex, setTabIndex] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    if (isInit) {
      return;
    }

    let queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams) {
      let token_key = urlParams.get("token");

      if (token_key) {
        setIsLoading(true);
        CALL_API_FUNCTIONS.GetApptResults(token_key)
          .then((resp) => {
            if (resp && resp.data) {
              setResult(resp.data);
            }
          })
          .catch((err) => {
            console.log("err", err);
            setError(true);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setError(true);
      }
    }

    setIsInit(true);
  }, [isLoading, result, tabIndex, isInit]);

  return (
    <div id="app_id" className="w-full font-sans bg-[#E6E6E6] flex flex-col justify-between h-[100vh]">
      {/* <div id="app_header_id" className="hidden">
        <Header />
      </div> */}

      {error ? (
        <div className="w-[80%] max-[830px]:w-[98%] bg-[#FFFFFF] border border-solid border-[#EDF0F8] rounded-[8px] relative mx-auto p-8 shadow-[0_6px_12px_rgba(61,81,164,0.08)] flex justify-center items-center">
          <h1 className="text-red-500">Link không tồn tại hoặc đã hết hạn!</h1>
        </div>
      ) : (
        <>
          <div
            id="app_content_id"
            className="w-[80%] max-[830px]:w-[98%] bg-[#FFFFFF] border border-solid border-[#EDF0F8] rounded-[8px] relative mx-auto mb-2"
          >
            <div className="sticky top-0 left-0 right-0 z-10 bg-[#0B5BAA] rounded-t-[8px] px-2 py-3 text-[#FFFFFF] font-medium max-[500px]:!text-[12px] text-[16px] leading-5 font-sans">
              {isLoading ? (
                <svg
                  className="inline-block w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : null}

              <span className="inline-block">
                Hồ sơ tại {result ? (result.site_name ? result.site_name : result.address) : ""}{" "}
                {result && result.date_of_examination ? moment(result.date_of_examination).format("DD/MM/YYYY") : ""}
              </span>
            </div>

            <div className="sticky max-[319px]:top-[64px] top-[44px] left-0 right-0 z-10 bg-[#FFFFFF] border-b-2 border-[#EDF0F8] grid grid-flow-col auto-cols-max overflow-x-auto max-[500px]:!text-[12px]">
              <div
                className={
                  "px-6 py-2 overflow-y-hidden overflow-ellipsis hover:cursor-pointer font-medium" +
                  (tabIndex === 1 ? " border-b-2 border-[#0052CC] text-[#0052CC]" : "")
                }
                onClick={() => setTabIndex(1)}
              >
                Kết luận
              </div>

              {result && result.image_analyse && result.image_analyse.length ? (
                <div
                  className={
                    "px-6 py-2 overflow-y-hidden overflow-ellipsis hover:cursor-pointer font-medium" +
                    (tabIndex === 2 ? " border-b-2 border-[#0052CC] text-[#0052CC]" : "")
                  }
                  onClick={() => setTabIndex(2)}
                >
                  Phiếu chẩn đoán hình ảnh
                </div>
              ) : null}

              {result && result.test && result.test.length ? (
                <div
                  className={
                    "px-6 py-2 overflow-y-hidden overflow-ellipsis hover:cursor-pointer font-medium" +
                    (tabIndex === 3 ? " border-b-2 border-[#0052CC] text-[#0052CC]" : "")
                  }
                  onClick={() => setTabIndex(3)}
                >
                  Kết quả xét nghiệm
                </div>
              ) : null}

              {result && result.drugs && result.drugs.length ? (
                <div
                  className={
                    "px-6 py-2 overflow-y-hidden overflow-ellipsis hover:cursor-pointer font-medium" +
                    (tabIndex === 4 ? " border-b-2 border-[#0052CC] text-[#0052CC]" : "")
                  }
                  onClick={() => setTabIndex(4)}
                >
                  Đơn thuốc
                </div>
              ) : null}
            </div>

            <div className="w-full rounded-b-[8px] p-4">
              {tabIndex === 1 && <ExaminationSummary data={result ? result : undefined} />}
              {tabIndex === 2 && <ExaminationImageAnalysis data={result ? result.image_analyse : undefined} />}
              {tabIndex === 3 && <ExaminationTest data={result ? result.test : undefined} />}
              {tabIndex === 4 && <ExaminationDrugs data={result ? result.drugs : undefined} />}
            </div>
          </div>

          <div className="w-full sticky left-0 right-0 bottom-0 z-10 bg-[#0B5BAA] p-4 text-[#FFFFFF] text-[14px] leading-[22px] font-normal text-center whitespace-pre-wrap break-words">
            Kết quả được cung cấp bởi Ứng dụng chăm sóc sức khỏe 365 Medihome và tuân thủ theo các{" "}
            <a
              className="underline"
              href="https://365medihome.com.vn/gioi-thieu/dieu-khoan-chinh-sach-bao-mat"
              target="_blank"
              rel="noopener noreferrer"
            >
              Điều khoản chính sách của ứng dụng
            </a>
            . <br />
            Để tra cứu kết quả chi tiết vui lòng{" "}
            <a className="underline" href="https://onelink.to/365medihome" target="_blank" rel="noopener noreferrer">
              Tải về ứng dụng 365 Medihome
            </a>{" "}
            hoặc liên hệ tổng dài hỗ trợ 19009204 nhánh 1.
          </div>
        </>
      )}
    </div>
  );
}

export default App;
