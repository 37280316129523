import axios from "axios";

export const CALL_API_FUNCTIONS = {
  GetApptResults,
};

function GetApptResults(token_key) {
  return axios({
    method: "post",
    url: process.env.REACT_APP_API_URL + "/patient/integration/vnpay/get-appt-results/" + token_key,
    headers: {},
  });
}
