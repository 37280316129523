import ImageViewer from "./commons/ImageViewer";

function ExaminationImageAnalysis(props) {
  return props.data && props.data.length
    ? props.data.map((e, index) => {
        return (
          <div id="examination_image_analysis_id" className="w-full max-[500px]:!text-[12px]" key={index}>
            <div className="flex flex-wrap w-full">
              <div className="w-1/6 max-[500px]:w-1/3 p-2">
                <ImageViewer heading="Phiếu chẩn đoán hình ảnh" images={e.images} imageViewerKey="examination_image_analysis" />
              </div>

              <div className="w-5/6 max-[500px]:w-2/3 pl-2">
                <div className="w-full border-b border-[#EDF0F8] py-2">
                  <h3 className="font-sans max-[500px]:!text-[16px] text-[20px] font-medium leading-6">{e.action_name}</h3>
                </div>

                <div className="w-full py-2">
                  <div className="flex flex-wrap">
                    <div className="w-1/6 max-[500px]:w-full text-[#6B778C]">Kết quả:</div>
                    <div className="w-5/6 max-[500px]:w-full">{e.request}</div>
                  </div>

                  <div className="flex flex-wrap">
                    <div className="w-1/6 max-[500px]:w-full text-[#6B778C]">Kết luận:</div>
                    <div className="w-5/6 max-[500px]:w-full">{e.summary}</div>
                  </div>
                </div>
              </div>
            </div>

            {index < props.data.length - 1 ? <hr className="my-2" /> : null}
          </div>
        );
      })
    : null;
}

export default ExaminationImageAnalysis;
