import ImageViewer from "./commons/ImageViewer";

function ExaminationTest(props) {
  return (
    <div id="examination_test_id" className="max-w-full">
      <table className="w-full border border-collapse border-[#EDF0F8] max-[500px]:!text-[12px]">
        <thead>
          <tr>
            <th className="bg-[#F3F7FF] border border-[#EDF0F8] uppercase max-[500px]:!text-[12px] text-[14px] text-[#1F2D3D] font-medium leading-[17px] max-[500px]:!px-[10px] max-[500px]:!py-[7px] px-[20px] py-[14px] text-center w-2"></th>
            <th className="bg-[#F3F7FF] border border-[#EDF0F8] uppercase max-[500px]:!text-[12px] text-[14px] text-[#1F2D3D] font-medium leading-[17px] max-[500px]:!px-[10px] max-[500px]:!py-[7px] px-[20px] py-[14px] text-left">
              Tên xét nghiệm
            </th>
            <th className="bg-[#F3F7FF] border border-[#EDF0F8] uppercase max-[500px]:!text-[12px] text-[14px] text-[#1F2D3D] font-medium leading-[17px] max-[500px]:!px-[10px] max-[500px]:!py-[7px] px-[20px] py-[14px] text-left">
              Đơn vị tính
            </th>
            <th className="bg-[#F3F7FF] border border-[#EDF0F8] uppercase max-[500px]:!text-[12px] text-[14px] text-[#1F2D3D] font-medium leading-[17px] max-[500px]:!px-[10px] max-[500px]:!py-[7px] px-[20px] py-[14px] text-left">
              Kết quả
            </th>
            <th className="bg-[#F3F7FF] border border-[#EDF0F8] uppercase max-[500px]:!text-[12px] text-[14px] text-[#1F2D3D] font-medium leading-[17px] max-[500px]:!px-[10px] max-[500px]:!py-[7px] px-[20px] py-[14px] text-left">
              CSBT
            </th>
          </tr>
        </thead>
        <tbody>
          {props.data && props.data.length
            ? props.data.map((e, index) => {
                return (
                  <>
                    <tr key={"action_name_" + index}>
                      <td
                        colSpan={5}
                        className="border border-[#EDF0F8] max-[500px]:!px-[10px] max-[500px]:!py-[5px] px-[20px] py-[10px] font-medium"
                      >
                        {e.action_name}
                      </td>
                    </tr>
                    {e.test_items && e.test_items.length ? (
                      e.test_items.map((item, idx) => {
                        return (
                          <tr key={"action_name_info_" + index + "_" + idx}>
                            <td className="border border-[#EDF0F8] text-center max-[500px]:!px-[10px] max-[500px]:!py-[5px] px-[20px] py-[10px] font-normal">
                              {idx + 1}
                            </td>
                            <td className="border border-[#EDF0F8] max-[500px]:!px-[10px] max-[500px]:!py-[5px] px-[20px] py-[10px] font-normal">
                              {item.action_name}
                            </td>
                            <td className="border border-[#EDF0F8] max-[500px]:!px-[10px] max-[500px]:!py-[5px] px-[20px] py-[10px] font-normal">
                              {item.unit}
                            </td>
                            <td className="border border-[#EDF0F8] max-[500px]:!px-[10px] max-[500px]:!py-[5px] px-[20px] py-[10px] font-normal">
                              {item.results}
                            </td>
                            <td className="border border-[#EDF0F8] max-[500px]:!px-[10px] max-[500px]:!py-[5px] px-[20px] py-[10px] font-normal">
                              {item.normal_value}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr key={"action_name_info_" + index}>
                        <td className="border border-[#EDF0F8] text-center max-[500px]:!px-[10px] max-[500px]:!py-[5px] px-[20px] py-[10px] font-normal">
                          1
                        </td>
                        <td className="border border-[#EDF0F8] max-[500px]:!px-[10px] max-[500px]:!py-[5px] px-[20px] py-[10px] font-normal">
                          {e.action_name}
                        </td>
                        <td className="border border-[#EDF0F8] max-[500px]:!px-[10px] max-[500px]:!py-[5px] px-[20px] py-[10px] font-normal">
                          {e.unit}
                        </td>
                        <td className="border border-[#EDF0F8] max-[500px]:!px-[10px] max-[500px]:!py-[5px] px-[20px] py-[10px] font-normal">
                          {e.results}
                        </td>
                        <td className="border border-[#EDF0F8] max-[500px]:!px-[10px] max-[500px]:!py-[5px] px-[20px] py-[10px] font-normal">
                          {e.normal_value}
                        </td>
                      </tr>
                    )}
                    {e.images && e.images.length ? (
                      <tr key={"action_images_" + index}>
                        <td colSpan={5} className="border border-[#EDF0F8]">
                          <ImageViewer heading="Kết quả xét nghiệm" images={e.images} imageViewerKey="examination_test" display="row" />
                        </td>
                      </tr>
                    ) : null}
                  </>
                );
              })
            : null}
        </tbody>
      </table>
    </div>
  );
}

export default ExaminationTest;
