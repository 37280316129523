import brandIcon from "../assets/images/brand-icon.png";

function Header() {
  return (
    <div id="header_id" className="fixed top-0 h-[64px] w-full bg-[#ffffff] shadow-[0_6px_12px_rgba(61,81,164,0.08)] px-20 flex items-center">
      <img src={brandIcon} alt="" height={36} width={80} />
    </div>
  );
}

export default Header;
