import ImageViewer from "./commons/ImageViewer";

function ExaminationSummary(props) {
  return (
    <div id="examination_summary_id" className="w-full max-[500px]:!text-[12px]">
      <div className="flex flex-wrap w-full odd:bg-[#FFFFFF] even:bg-[#F1F4FA]">
        <div className="w-1/6 max-[500px]:w-full max-[500px]:px-1 max-[500px]:py-1 px-2 py-3 text-[#6B778C]">Mã bệnh nhân:</div>
        <div className="w-5/6 max-[500px]:w-full max-[500px]:px-1 max-[500px]:py-1 px-2 py-3">
          {props.data ? props.data.patient_record_code : null}
        </div>
      </div>

      <div className="flex flex-wrap w-full odd:bg-[#FFFFFF] even:bg-[#F1F4FA]">
        <div className="w-1/6 max-[500px]:w-full max-[500px]:px-1 max-[500px]:py-1 px-2 py-3 text-[#6B778C]">Triệu chứng:</div>
        <div className="w-5/6 max-[500px]:w-full max-[500px]:px-1 max-[500px]:py-1 px-2 py-3">{props.data ? props.data.pathological : null}</div>
      </div>

      <div className="flex flex-wrap w-full odd:bg-[#FFFFFF] even:bg-[#F1F4FA]">
        <div className="w-1/6 max-[500px]:w-full max-[500px]:px-1 max-[500px]:py-1 px-2 py-3 text-[#6B778C]">Lý do khám:</div>
        <div className="w-5/6 max-[500px]:w-full max-[500px]:px-1 max-[500px]:py-1 px-2 py-3">
          {props.data ? props.data.cause_of_examination : null}
        </div>
      </div>

      <div className="flex flex-wrap w-full odd:bg-[#FFFFFF] even:bg-[#F1F4FA]">
        <div className="w-1/6 max-[500px]:w-full max-[500px]:px-1 max-[500px]:py-1 px-2 py-3 text-[#6B778C]">Chẩn đoán:</div>
        <div className="w-5/6 max-[500px]:w-full max-[500px]:px-1 max-[500px]:py-1 px-2 py-3">{props.data ? props.data.diagnose : null}</div>
      </div>

      <div className="flex flex-wrap w-full odd:bg-[#FFFFFF] even:bg-[#F1F4FA]">
        <div className="w-1/6 max-[500px]:w-full max-[500px]:px-1 max-[500px]:py-1 px-2 py-3 text-[#6B778C]">Kết luận:</div>
        <div className="w-5/6 max-[500px]:w-full max-[500px]:px-1 max-[500px]:py-1 px-2 py-3">{props.data ? props.data.summary : null}</div>
      </div>

      {props.data && props.data.attachments && props.data.attachments.length ? (
        <div className="w-full">
          <ImageViewer heading="Kết luận" images={props.data.attachments} imageViewerKey="examination_summary" display="row" />
        </div>
      ) : null}
    </div>
  );
}

export default ExaminationSummary;
